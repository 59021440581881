var primaryDropdownItem = document.querySelector('.lang-anchor--primary');
var dropdownContainer = document.querySelector('.lang-anchors');
var dropdownContent = document.querySelector('.dropdown-content');

if (dropdownContainer != null) {
    var originalText = primaryDropdownItem.innerHTML;

    dropdownContainer.addEventListener('click', function () {
        primaryDropdownItem.innerHTML="SELECT LANGUAGE";
        // dropdownContent.classList.toggle('active');

        if (dropdownContent.classList.contains("active")) {
            dropdownContent.classList.remove("active");
            primaryDropdownItem.innerHTML=originalText;
        }else {
            dropdownContent.classList.add("active");
        };
    });

    if (document.body.clientWidth > 768) {

        dropdownContainer.addEventListener('mouseenter' || 'click', function () {
            primaryDropdownItem.innerHTML="SELECT LANGUAGE";
            dropdownContent.classList.toggle('active');
            // dropdownContainer.classList.add("lang-anchors--alt");
            console.log("I see you!");
        });
    
        dropdownContainer.addEventListener('mouseleave', function () {
            primaryDropdownItem.innerHTML=originalText;
            dropdownContent.classList.remove("active");
            // dropdownContainer.classList.remove("lang-anchors--alt");
        });
    } else{
        
    }
};

console.log(primaryDropdownItem);
console.log(dropdownContainer);